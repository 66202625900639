import { useEffect, useState, useContext, useRef } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Tabs,
  Radio,
  Alert,
  Space,
  Dropdown,
  Menu,
} from "antd";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  EditFilled,
  DownOutlined,
  CloseOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  TAX_PERCENTAGE,
  TRANSACTION_TYPE_CASH,
  // TRANSACTION_TYPE_MADA,
  // TRANSACTION_TYPE_STC,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_MULTI,
} from "../../../configs/constants";
import {
  getMyProfile,
  createTransactions,
  getBranchDiscounts,
} from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import TransactionSlip from "../../../components/TransactionSlip/TransactionSlip";
import ReactToPrint from "react-to-print";
import "./POS.css";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const POS = () => {
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [grandTotalAmount, setGrandTotalAmount] = useState(0);
  const [grandtotalVAT, setGrandTotalVAT] = useState(0);
  const [activeCategory, setActiveCategory] = useState(0);
  const [checkoutModalVisible, setCheckoutModalVisible] = useState(false);
  const [editPriceModalVisible, setEditPriceModalVisible] = useState(false);
  const [editPriceModalTitle, setEditPriceModalTitle] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    TRANSACTION_TYPE_CASH
  );
  const [transaction, setTransaction] = useState(false);
  const { authUser, roundOff, appConfig, fullScreenPOS, setfullScreenPOS } =
    useContext(AppContext);
  const [returnAmountMsg, setReturnAmountMsg] = useState("");
  const [cashCollected, setCashCollected] = useState("");
  const [branchDiscounts, setBranchDiscounts] = useState("");
  const [discountValue, setDiscountValue] = useState(0);
  const [discountId, setDiscountId] = useState("");
  const [disableProceed, setDisableProceed] = useState(false);
  const [checkoutForm] = Form.useForm();
  const [editPriceForm] = Form.useForm();
  const transactionSlipRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    getMyProfile().then((response) => {
      let categories = [{ id: 0, name: t("all"), name_ar: t("الجميع") }];
      let products = [];
      response.data.data.user.categories.forEach((category) => {
        categories.push({
          id: category.id,
          name: category.name,
          name_ar: category.name_ar,
        });

        products = [...products, ...category.products];
      });
      setProductCategoriesList(categories);
      setProductsList(products);
    });
  }, [t]);

  useEffect(() => {
    getBranchDiscounts().then((response) => {
      setBranchDiscounts(response.data.data.discounts);
    });
  }, []);

  useEffect(() => {
    let taxTotal = 0;
    let total = 0;
    let tax = 0;
    let subTotal = 0;
    cart.forEach((product) => {
      subTotal = product.price * product.quantity;
      if (product.is_taxable === true) {
        tax = subTotal * TAX_PERCENTAGE;
        taxTotal = taxTotal + tax;
        subTotal = subTotal + tax;
      }
      total = total + subTotal;
    });
    setTotalVAT(roundOff(taxTotal));
    setTotalAmount(roundOff(total));
  }, [cart, setTotalAmount, setTotalVAT, roundOff]);

  const cartReducer = (product, action) => {
    let cartNew = JSON.parse(JSON.stringify(cart));
    let productId = product.id;
    let indexFound = cartNew.findIndex((product) => product.id === productId);

    if (action === "add") {
      if (indexFound === -1) {
        product.quantity = 1;
        cartNew.push(product);
      } else {
        cartNew[indexFound].quantity++;
      }
    } else if (action === "inc") {
      cartNew[indexFound].quantity++;
    } else if (action === "dec") {
      cartNew[indexFound].quantity--;
      if (cartNew[indexFound].quantity === 0) {
        cartNew.splice(indexFound, 1);
      }
    } else if (action === "edit_price") {
      cartNew[indexFound].price = roundOff(product.price);
    }

    setCart(cartNew);
  };

  const addProduct = (product) => {
    cartReducer(product, "add");
    setGrandTotalVAT(0);
    setGrandTotalAmount(0);
    setDiscountValue(0);
    setDiscountId("");
  };

  const incrementProduct = (product) => {
    cartReducer(product, "inc");
    setGrandTotalVAT(0);
    setGrandTotalAmount(0);
    setDiscountValue(0);
    setDiscountId("");
  };

  const decrementProduct = (product) => {
    cartReducer(product, "dec");
    setGrandTotalVAT(0);
    setGrandTotalAmount(0);
    setDiscountValue(0);
    setDiscountId("");
  };

  const checkoutFormInitialValues = {
    payment_type: TRANSACTION_TYPE_CASH,
    dynamic_field: "",
  };

  const showCheckoutModal = () => {
    setTransaction(false);
    setReturnAmountMsg("");
    setCheckoutModalVisible(true);
    setSelectedPaymentType(TRANSACTION_TYPE_CASH);
    checkoutForm.resetFields();
  };

  const hideCheckoutModal = () => {
    setCheckoutModalVisible(false);
    setCashCollected("");
  };

  const handleCheckout = () => {
    setDisableProceed(true);
    let billTotalAmount = discountValue === 0 ? totalAmount : grandTotalAmount;
    checkoutForm.validateFields().then((values) => {
      let transactionObj = {
        total: roundOff(billTotalAmount),
      };
      if (selectedPaymentType === TRANSACTION_TYPE_CASH) {
        let amountDiff =
          parseFloat(values.dynamic_field || 0) - roundOff(billTotalAmount);
        if (amountDiff < 0) {
          alert(t("ask_more_cash"));
          return false;
        }
        if (amountDiff > 0) {
          let msg = (
            <>
              {t("return_back_amount") + " "}
              <CurrencyFormatter value={roundOff(amountDiff)} />
            </>
          );
          setReturnAmountMsg(msg);
        }
        transactionObj.cash_collected = values.dynamic_field;
      } else {
        if (selectedPaymentType === TRANSACTION_TYPE_MULTI) {
          let cashCollect =
            Number(values.collected_by_cash_multi) +
            Number(values.collected_by_card_multi);
          let amountDiff =
            parseFloat(cashCollect || 0) - roundOff(billTotalAmount);
          if (amountDiff < 0) {
            alert(t("ask_more_cash"));
            return false;
          }
          transactionObj.multipayments = JSON.stringify([
            {
              transaction_type: TRANSACTION_TYPE_CASH,
              amount: Number(values.collected_by_cash_multi),
            },
            {
              transaction_type: TRANSACTION_TYPE_CREDIT,
              amount: Number(values.collected_by_card_multi),
            },
          ]);
        }
        transactionObj.reference = values.dynamic_field;
      }
      transactionObj.type = values.payment_type;
      transactionObj.items = JSON.stringify(
        cart.map((item) => ({
          id: item.id,
          qty: item.quantity,
          price: item.price,
        }))
      );

      // Add Company Name and VAT if added
      if (values.buyer_company_name) {
        transactionObj.buyer_company_name = values.buyer_company_name;
      }
      if (values.buyer_company_vat) {
        transactionObj.buyer_company_vat = values.buyer_company_vat;
      }
      if (discountId) {
        transactionObj.discount_id = discountId;
      }

      createTransactions(transactionObj).then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setTransaction(response.data.data.transaction);
          // Reset Payment Type
          setSelectedPaymentType(TRANSACTION_TYPE_CASH);
          // Reset Form Fields
          checkoutForm.resetFields();
          // Reset Cart
          setCart([]);
          setCashCollected("");
          setDiscountValue(0);
          setDiscountId("");
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      });
      setDisableProceed(false);
    });
  };

  const showEditPriceModal = (product_id) => {
    if (cart.length > 0 && authUser.allow_editable_price === true) {
      const product = cart.find((product) => product.id === product_id);
      if (product) {
        setEditPriceModalVisible(true);
        setEditPriceModalTitle(
          t("update") + " " + t("price") + " : " + product.name
        );
        editPriceForm.setFieldsValue({
          price: product.price,
          product_id: product_id,
        });
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          t("product_not_found")
        );
      }
    }
  };

  const hideEditPriceModal = () => {
    setEditPriceModalVisible(false);
  };

  const handleCashMultiPayment = (e) => {
    let amount = e.target.value;
    checkoutForm.setFieldsValue({
      collected_by_cash_multi: amount,
      collected_by_card_multi: (
        (discountValue === 0 ? totalAmount : grandTotalAmount) - amount
      ).toFixed(2),
    });
  };

  const handleCardMultiPayment = (e) => {
    let amount = e.target.value;
    checkoutForm.setFieldsValue({
      collected_by_card_multi: amount,
      collected_by_cash_multi: (
        (discountValue === 0 ? totalAmount : grandTotalAmount) - amount
      ).toFixed(2),
    });
  };

  const handleEditPrice = () => {
    editPriceForm.validateFields().then((values) => {
      const productPriceToUpdate = {
        id: values.product_id,
        price: values.price,
      };
      cartReducer(productPriceToUpdate, "edit_price");
      setEditPriceModalVisible(false);
      setGrandTotalVAT(0);
      setGrandTotalAmount(0);
      setDiscountValue(0);
      setDiscountId("");
    });
  };

  const handleDiscount = (id) => {
    const discount_id = parseInt(id.key);
    const discount = branchDiscounts.find(
      (discount) => discount.id === discount_id
    );
    setDiscountId(discount_id);
    setDiscountValue(discount.discount_percentage);
    if (discount) {
      const discount_percentage = discount.discount_percentage / 100;
      const amountValue = totalAmount * discount_percentage;
      const vatValue = totalVAT * discount_percentage;
      setGrandTotalVAT(totalVAT - vatValue);
      setGrandTotalAmount(totalAmount - amountValue);
    }
  };

  const options = (
    <Menu onClick={handleDiscount} style={{ direction: "ltr" }}>
      {branchDiscounts &&
        branchDiscounts.map((branch) => (
          <Menu.Item key={branch.id}>
            {branch.discount_percentage + " %"}
          </Menu.Item>
        ))}
    </Menu>
  );

  const discountReset = () => {
    setGrandTotalVAT(0);
    setGrandTotalAmount(0);
    setDiscountValue(0);
    setDiscountId("");
  };

  return (
    <>
      <Row gutter={{ xs: 10, sm: 20, md: 20, lg: 20 }}>
        <Col key="productsList-col" span={18}>
          <Card bodyStyle={{ display: "flex", alignItems: "flex-start" }}>
            <Tabs
              defaultActiveKey="0"
              onChange={(category) => setActiveCategory(category)}
              className="pos_tabs"
            >
              {productCategoriesList.map((category) => (
                <Tabs.TabPane
                  tab={
                    appConfig.direction === "rtl"
                      ? category.name_ar
                      : category.name
                  }
                  key={category.id}
                >
                  <Row gutter={24}>
                    {productsList.map((product) => {
                      if (
                        parseInt(activeCategory) === product.category_id ||
                        parseInt(activeCategory) === 0
                      ) {
                        return (
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={12}
                            md={8}
                            lg={6}
                            xl={6}
                            key={"product-" + product.id}
                          >
                            <Card
                              key={product.id}
                              style={{ marginTop: 10 }}
                              onClick={() => addProduct(product)}
                              hoverable
                              cover={
                                <img
                                  alt={product.name}
                                  src={
                                    product.image
                                      ? product.image
                                      : "/images/image-not-found.png"
                                  }
                                />
                              }
                            >
                              <Card.Meta
                                title={
                                  appConfig.direction === "rtl"
                                    ? product.name
                                    : product.name_en
                                }
                                description={
                                  <CurrencyFormatter value={product.price} />
                                }
                              />
                            </Card>
                          </Col>
                        );
                      }
                      return false;
                    })}
                  </Row>
                </Tabs.TabPane>
              ))}
            </Tabs>
            <Button
              onClick={() => setfullScreenPOS(!fullScreenPOS)}
              type="primary"
              style={{ height: "auto", padding: "10px" }}
            >
              {fullScreenPOS ? (
                <FullscreenExitOutlined
                  style={{ fontSize: "20px", display: "flex" }}
                />
              ) : (
                <FullscreenOutlined
                  style={{ fontSize: "20px", display: "flex" }}
                />
              )}
            </Button>
          </Card>
        </Col>
        <Col key="cart-col" span={6}>
          <div
            style={{
              height: fullScreenPOS
                ? "calc(100vh - 48px)"
                : "calc(100vh - 154px)",
              backgroundColor: "#ffffff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Card
              title={t("cart")}
              bordered={true}
              headStyle={{ fontSize: "24px" }}
              className="cart_card"
            >
              {cart.length === 0 && (
                <div style={{ textAlign: "center" }}>{t("add_some_item")}</div>
              )}
              {cart.map((product) => {
                let subTotal = product.price * product.quantity;
                if (product.is_taxable === true) {
                  subTotal += subTotal * TAX_PERCENTAGE;
                }

                return (
                  <div
                    className="cart-products-list-item"
                    key={"cart-item-" + product.id}
                  >
                    <strong className="product-name">
                      <span>{product.name}</span> - {product.name_en}
                    </strong>
                    <div className="quantity_row">
                      <div>
                        Qty:
                        <MinusCircleFilled
                          style={{
                            color: "#6301f2",
                            fontSize: "16px",
                            marginLeft: "5px",
                          }}
                          onClick={() => decrementProduct(product)}
                        />
                        <strong style={{ margin: "0 10px" }}>
                          {product.quantity}
                        </strong>
                        <PlusCircleFilled
                          style={{
                            color: "#6301f2",
                            fontSize: "16px",
                            // borderRadius: "15px",
                            // backgroundColor: "#6301f2",
                          }}
                          onClick={() => incrementProduct(product)}
                        />
                      </div>
                      <div>
                        Price:{" "}
                        <span className="brand-color-purple">
                          <CurrencyFormatter value={product.price} />
                          {authUser.allow_editable_price === true && (
                            <EditFilled
                              style={{ color: "#F83A12" }}
                              onClick={() => showEditPriceModal(product.id)}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      SubTotal:{" "}
                      <span className="subTotal brand-color-purple">
                        <CurrencyFormatter value={roundOff(subTotal)} />
                      </span>
                    </div>
                  </div>
                );
              })}
            </Card>
            {cart.length !== 0 && (
              <Card>
                {branchDiscounts.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      columnGap: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    <Dropdown overlay={options} trigger={["click"]}>
                      <Button>
                        {discountValue === 0
                          ? t("select_a_discount")
                          : discountValue + " %"}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    {discountValue !== 0 && (
                      <Button onClick={discountReset} danger>
                        <CloseOutlined />
                      </Button>
                    )}
                  </div>
                )}

                <h4>
                  {t("total_vat")}:{" "}
                  <CurrencyFormatter
                    value={discountValue === 0 ? totalVAT : grandtotalVAT}
                  />
                </h4>
                <h4>
                  {t("total_amount")}:{" "}
                  <CurrencyFormatter
                    value={discountValue === 0 ? totalAmount : grandTotalAmount}
                  />
                </h4>

                <Button
                  type="primary"
                  size="large"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                  disabled={cart.length === 0}
                  onClick={showCheckoutModal}
                >
                  {t("checkout")}
                </Button>
              </Card>
            )}
          </div>
        </Col>
      </Row>

      <Modal
        visible={checkoutModalVisible}
        title={t("checkout")}
        onCancel={hideCheckoutModal}
        footer={[
          <Button
            key="proceed"
            type="primary"
            onClick={handleCheckout}
            style={transaction ? { display: "none" } : {}}
            disabled={disableProceed}
          >
            {t("proceed")}
          </Button>,
          transaction && (
            <ReactToPrint
              trigger={() => (
                <Button key="print" type="primary">
                  {t("print")}
                </Button>
              )}
              content={() => transactionSlipRef.current}
            />
          ),
          <Button key="back" onClick={hideCheckoutModal}>
            {t("back")}
          </Button>,
        ]}
      >
        {transaction === false ? (
          <>
            <h3>
              {t("charged_amount")} :{" "}
              <span style={{ color: "#6301f2" }}>
                <CurrencyFormatter
                  value={discountValue === 0 ? totalAmount : grandTotalAmount}
                />
              </span>
              <br />
              <small>
                {t("including_vat")}:{" "}
                <span style={{ color: "#6301f2" }}>
                  <CurrencyFormatter
                    value={discountValue === 0 ? totalVAT : grandtotalVAT}
                  />
                </span>
              </small>
            </h3>
            <h4>
              {t("payment_type")} :{" "}
              {selectedPaymentType === TRANSACTION_TYPE_CASH && t("cash")}
              {selectedPaymentType === TRANSACTION_TYPE_CREDIT && t("card")}
              {selectedPaymentType === TRANSACTION_TYPE_MULTI &&
                t("multi_payment")}
            </h4>
            {selectedPaymentType === TRANSACTION_TYPE_CASH && cashCollected && (
              <h4>
                {t("return_back_amount")} :{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {cashCollected <
                  (discountValue === 0 ? totalAmount : grandTotalAmount)
                    ? t("collect_more_cash")
                    : (
                        cashCollected -
                        (discountValue === 0 ? totalAmount : grandTotalAmount)
                      ).toFixed(2) + " SAR"}
                </span>
              </h4>
            )}

            <Form
              name="checkout-form"
              layout="vertical"
              onFinish={handleCheckout}
              style={{ width: "100%", margin: "0" }}
              form={checkoutForm}
              initialValues={checkoutFormInitialValues}
            >
              <Form.Item name="payment_type">
                <Radio.Group
                  onChange={(e) => setSelectedPaymentType(e.target.value)}
                  size="small"
                >
                  <Space size={[20, 20]} wrap>
                    <Radio.Button
                      style={{ height: "auto" }}
                      value={TRANSACTION_TYPE_CASH}
                    >
                      <img
                        src="/images/payment_type_cash.png"
                        alt="Payment Type Cash"
                      />
                    </Radio.Button>
                    <Radio.Button
                      style={{ height: "auto" }}
                      value={TRANSACTION_TYPE_CREDIT}
                    >
                      <img
                        src="/images/payment_type_cc.png"
                        alt="Payment Type Credit Card"
                      />
                    </Radio.Button>
                    <Radio.Button
                      style={{ height: "auto" }}
                      value={TRANSACTION_TYPE_MULTI}
                    >
                      <img
                        src="/images/payment_type_cash.png"
                        alt="Payment Type Cash"
                      />
                      +
                      <img
                        src="/images/payment_type_cc.png"
                        alt="Payment Type Credit Card"
                      />
                    </Radio.Button>
                  </Space>
                </Radio.Group>
              </Form.Item>

              {selectedPaymentType === TRANSACTION_TYPE_MULTI && (
                <div style={{ display: "flex", columnGap: "20px" }}>
                  <Form.Item
                    name="collected_by_cash_multi"
                    label={t("collected_by_cash")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_cash_required"),
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input type="number" onChange={handleCashMultiPayment} />
                  </Form.Item>
                  <Form.Item
                    name="collected_by_card_multi"
                    label={t("collected_by_card")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_card_required"),
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input type="number" onChange={handleCardMultiPayment} />
                  </Form.Item>
                </div>
              )}

              <Form.Item
                name="dynamic_field"
                rules={[
                  {
                    required: true,
                    message:
                      selectedPaymentType === TRANSACTION_TYPE_CASH
                        ? t("validate_cash_required")
                        : t("validate_reference_required"),
                  },
                ]}
                label={
                  selectedPaymentType === TRANSACTION_TYPE_CASH
                    ? t("cash_collected")
                    : t("reference_no")
                }
              >
                <Input
                  type="number"
                  onChange={(e) => {
                    setCashCollected(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="buyer_company_name"
                label={t("buyer_company_name")}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="buyer_company_vat"
                label={t("buyer_company_vat")}
                rules={[
                  {
                    min: 15,
                    message: t("validate_vat_number_exact_limit"),
                  },
                  {
                    max: 15,
                    message: t("validate_vat_number_exact_limit"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            {returnAmountMsg && (
              <Alert message={returnAmountMsg} type="info" showIcon />
            )}
            <TransactionSlip
              ref={transactionSlipRef}
              transaction={transaction}
              shop={{
                name: authUser.business_name,
                vat: authUser.vat,
                logo: authUser.logo,
                is_vat_exempt: authUser.is_vat_exempt,
              }}
            />
          </>
        )}
      </Modal>

      <Modal
        visible={editPriceModalVisible}
        onCancel={hideEditPriceModal}
        onOk={handleEditPrice}
        okText={t("update")}
        cancelText={t("cancel")}
        title={editPriceModalTitle}
      >
        <Form
          name="edit-price-form"
          layout="vertical"
          style={{ width: "100%", margin: "0" }}
          form={editPriceForm}
        >
          <Form.Item
            name="price"
            label={t("price")}
            rules={[
              {
                required: true,
                message: t("validate_product_price_required"),
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="product_id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default POS;
